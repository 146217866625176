<template>
  <v-img
    v-if="imgResp.src"
    :aspect-ratio="aspectRatio"
    :sizes="imgResp.sizes"
    :src="imgResp.src"
    :srcset="imgResp.srcset"
    alt="Cover image for video"
    cover
  >
    <slot />
  </v-img>
</template>

<script setup>
import { computed, onMounted, ref, toRef } from 'vue'
import { useImgResponsive } from '#root/lib/img'

const props = defineProps({
  aspectRatio: {
    default: 1,
    type: Number
  },
  value: {
    required: true,
    type: Object
  }
})

const embedResponse = ref(null)
const defaultSrc = computed(() => {
  let src

  switch (props.value.player) {
    case 'youtube':
      src = `https://img.youtube.com/vi/${props.value.external_id}/0.jpg`
      break
    case 'YouTube':
      src = `https://img.youtube.com/vi/${props.value.video_id}/0.jpg`
      break
    case 'vimeo':
      src = embedResponse.value && embedResponse.value.thumbnail_url
      break
    case 'Vimeo':
      src = embedResponse.value && embedResponse.value.thumbnail_url
      break
  }

  return src
})
const imgResp = useImgResponsive(
  toRef(() => ({
    aspectRatio: props.aspectRatio,
    image: props.value.cover_image
      ? props.value.cover_image
      : { src: defaultSrc.value }
  }))
)

onMounted(async () => {
  switch (props.value.player) {
    case 'vimeo':
      if (!props.value.cover_image) {
        const url = `https://vimeo.com/${props.value.external_id}`
        const resp = await fetch(
          `https://vimeo.com/api/oembed.json?url=${encodeURIComponent(url)}`
        )
        if (resp.ok) embedResponse.value = await resp.json()
      }
      break
    case 'Vimeo':
      if (!props.value.cover_image) {
        const url = `https://vimeo.com/${props.value.video_id}`
        const resp = await fetch(
          `https://vimeo.com/api/oembed.json?url=${encodeURIComponent(url)}`
        )
        if (resp.ok) embedResponse.value = await resp.json()
      }
      break
  }
})
</script>
